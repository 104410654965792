var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"my-2 rounded text-left"},[_c('p',{staticClass:"text-left pbz-font subtitle-md-medium"},[_vm._v(" Edit "+_vm._s(_vm.$t('sideBar.global_variable'))+" ")]),_c('a-form-model',{ref:"formData",attrs:{"novalidate":"","model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.editGlobalVariable.apply(null, arguments)}}},[_c('fieldset',{attrs:{"disabled":_vm.loadingData || _vm.loadingEdit}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('price_setting.label_variable'))+" "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-form-model-item',{staticClass:"mb-3",attrs:{"prop":"symbol","rules":[
          {
            required: true,
            message: _vm.$t('price_setting.error_variable_name'),
          },
          {
            pattern: /^[0-9a-zA-Z_]*$/,
            message: _vm.$t('price_setting.error_variable_name_pattern'),
          },
          {
            max: 30,
            message: _vm.$t('price_setting.error_max_variable_name'),
          }
        ]}},[_c('a-input',{staticClass:"bg-light text-dark",attrs:{"size":"large","disabled":""},model:{value:(_vm.form.symbol),callback:function ($$v) {_vm.$set(_vm.form, "symbol", $$v)},expression:"form.symbol"}})],1),_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('price_setting.label_description'))+" ")]),_c('a-form-model-item',{staticClass:"mb-3",attrs:{"prop":"name","rules":[{
          max: 30,
          message: _vm.$t('price_setting.error_description'),
        }]}},[_c('a-input',{attrs:{"size":"large","allow-clear":"","disabled":_vm.loadingData || _vm.loadingEdit},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('label',{staticClass:"mb-1"},[_vm._v(" Value "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-form-model-item',{attrs:{"prop":"default_value","rules":[
          {
            required: true,
            message: _vm.$t('price_setting.error_value_empty'),
          },
          {
            validator: (rule, value, cb) => {
              ('' + value).length > 12 ? cb(true) : cb()
            },
            message: 'Maximum 12 digit',
          }
        ]}},[_c('a-input-number',{staticClass:"w-100",attrs:{"size":"large","min":0,"disabled":_vm.loadingData || _vm.loadingEdit},model:{value:(_vm.form.default_value),callback:function ($$v) {_vm.$set(_vm.form, "default_value", $$v)},expression:"form.default_value"}})],1),_c('div',{staticClass:"text-right mt-3"},[_c('a-button',{staticClass:"text-primary font-weight-bold mr-2",attrs:{"type":"link","size":"large","disabled":_vm.loadingEdit},on:{"click":function($event){return _vm.$router.push({ path: '/global-variable', query: _vm.$route.query })}}},[_vm._v(" "+_vm._s(_vm.$t('utils.cancel'))+" ")]),(_vm.permission.includes('WRITE'))?_c('a-button',{staticClass:"font-weight-bold",attrs:{"type":"primary","size":"large","html-type":"submit","disabled":_vm.loadingData,"loading":_vm.loadingEdit}},[_vm._v(" "+_vm._s(_vm.$t('utils.save'))+" ")]):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }